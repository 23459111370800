//App.js
import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import WebSocketProvider from './WebSocketProvider';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import SideDrawer from './SideDrawer'; // Import a side-drawer component for threads
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './App.css';

function App() {
    const [clientPhone, setClientPhone] = useState('');
    const [sessionId, setSessionId] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [threads, setThreads] = useState([]); // Manage the list of threads
    const [currentThreadId, setCurrentThreadId] = useState(''); // Manage the active thread
    const [drawerWidth, setDrawerWidth] = useState(200); // Initial width for the SideDrawer
    const [isMobile, setIsMobile] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const resizerRef = useRef();
    const socketRef = useRef();
    const operationQueue = useRef([]);


    useEffect(() => {
        const validateAndSetThreadId = (threadId) => {
            if (threadId && threads.some((thread) => thread.id === threadId)) {
                // If the thread ID is valid, set it as the current thread
                setCurrentThreadId(threadId);
                localStorage.setItem('lastSelectedThread', threadId); // Persist to localStorage
            } else {
                // Fallback to the last selected thread from localStorage or 'general'
                const storedThreadId = localStorage.getItem('lastSelectedThread') || 'general';
                setCurrentThreadId(storedThreadId);
                const newUrl = `${window.location.origin}?t=${storedThreadId}`;
                window.history.replaceState({}, '', newUrl);
            }
        };

        // Determine initial thread ID from the URL or localStorage
        const initialThreadId = new URL(window.location.href).searchParams.get('t') ||
            localStorage.getItem('lastSelectedThread') ||
            'general';

        validateAndSetThreadId(initialThreadId);

        const handlePopState = () => {
            const newThreadId = new URL(window.location.href).searchParams.get('t');
            validateAndSetThreadId(newThreadId);
        };

        window.addEventListener('popstate', handlePopState);
        return () => window.removeEventListener('popstate', handlePopState);
    }, [threads]);

    // useEffect(() => {
    //     if (currentThreadId) {
    //         localStorage.setItem('lastSelectedThread', currentThreadId);
    //     }
    // }, [currentThreadId]);


    useEffect(() => {
        const storedLoggedIn = localStorage.getItem('loggedIn');
        const storedClientPhone = localStorage.getItem('clientPhone');
        const storedSessionId = localStorage.getItem('sessionId');
        const storedThreads = localStorage.getItem('threads');
        const storedCurrentThreadId = localStorage.getItem('currentThreadId');

        if (storedThreads) {
            setThreads(JSON.parse(storedThreads));
        }

        console.log('Stored logged in:', storedLoggedIn);
        console.log('Stored client phone:', storedClientPhone);
        console.log('Stored session ID:', storedSessionId);

        if (storedLoggedIn === 'true' && storedClientPhone && storedSessionId) {
            setLoggedIn(true);
            setClientPhone(storedClientPhone);
            setSessionId(storedSessionId);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() => {
    //     if (threads.length > 0) {
    //         localStorage.setItem('threads', JSON.stringify(threads));
    //     }
    // }, [threads]);

    useEffect(() => {
        if (loggedIn) {
            localStorage.setItem('loggedIn', 'true');
            localStorage.setItem('clientPhone', clientPhone);
            localStorage.setItem('sessionId', sessionId);
            localStorage.setItem('currentThreadId', currentThreadId);
            if (threads.length > 0) {
                localStorage.setItem('threads', JSON.stringify(threads));
            }
        } else {
            localStorage.removeItem('loggedIn');
            localStorage.removeItem('clientPhone');
            localStorage.removeItem('sessionId');
            localStorage.removeItem('currentThreadId');
            localStorage.removeItem('threads');
        }
    }, [loggedIn, clientPhone, sessionId]);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    const handleMouseMove = (e) => {
        const newWidth = Math.min(Math.max(e.clientX, 150), 400); // Limit width between 150px and 400px
        setDrawerWidth(newWidth);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const validatePhoneNumber = (number) => {
        // At least 3 characters and no whitespace
        return number.length >= 3 && !/\s/.test(number);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validatePhoneNumber(clientPhone)) {
            setClientPhone(clientPhone);
            setLoggedIn(true);
        }
    };

    const handleJoinedThread = (threadId, threadName) => {
        console.log('Joining thread:', threadId);
        setThreads((prevThreads) => {
            const updatedThreads = prevThreads.some((thread) => thread.id === threadId)
                ? prevThreads
                : [...prevThreads, { id: threadId, name: threadName }];

            // Select the new thread immediately after updating threads
            handleThreadSelectWithUpdatedThreads(threadId, updatedThreads);
            return updatedThreads;
        });
    };


    // Helper function to select a thread with updated threads
    const handleThreadSelectWithUpdatedThreads = (threadId, updatedThreads) => {
        if (updatedThreads.some((thread) => thread.id === threadId)) {
            console.log('Switching to thread:', threadId);
            setCurrentThreadId(threadId);

            // Update the URL without reloading the page
            const newUrl = `${window.location.origin}?t=${threadId}`;
            window.history.pushState({ threadId }, '', newUrl);
        } else {
            console.warn('Attempted to switch to an invalid thread:', threadId);
        }
    };

    const handleThreadSelect = (threadId) => {
        if (threads.some((thread) => thread.id === threadId)) {
            console.log('Switching to thread:', threadId);
            setCurrentThreadId(threadId);

            // Update the URL without reloading the page
            const newUrl = `${window.location.origin}?t=${threadId}`;
            window.history.pushState({ threadId }, '', newUrl);
        } else {
            console.warn('Attempted to switch to an invalid thread:', threadId);
        }
    };

    const handleNewThread = (threadName) => {
        console.log('Creating new thread:', threadName);
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'new_thread',
                    threadName: threadName,
                    sessionId: sessionId,
                })
            )
        }
    };

    const handleJoinThread = (threadId) => {
        console.log('Joining thread:', threadId);
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'join_thread',
                    threadId: threadId,
                    sessionId: sessionId,
                })
            );
            console.log('SENT JOIN_THREAD MESSAGE');
        }
        console.log('MADE IT TO THE END OF JOIN THREAD');
    };

    const handleLoginAttempt = () => {
        console.log('Send Login Message to Server');
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'login',
                    phoneNumber: clientPhone,
                })
            );
        }
    };

    const handleLogout = () => {
        console.log('Send Logout Message to Server');
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'logout',
                    sessionId: sessionId,
                })
            );
        }
        setLoggedIn(false)
        setSessionId(null);
        setThreads([]);
        setCurrentThreadId('');
    };

    const handleThreadAdded = (threadId, threadName, forSessionId) => {
        console.log('Thread created:', threadId, threadName, forSessionId);
        console.log('Existing threads: ', threads);
        setThreads((prevThreads) => {
            if (!prevThreads.find((thread) => thread.id === threadId)) {
                return [...prevThreads, { id: threadId, name: threadName }];
            }
            return prevThreads;
        });

        handleThreadSelect(threadId);

    };

    if (!loggedIn) {
        return (
            <div className="client-setup">
                <form onSubmit={handleSubmit}>
                    <h2>Phone Number</h2>
                    <input
                        type="text"
                        value={clientPhone}
                        onChange={(e) => setClientPhone(e.target.value)}
                        placeholder="Your Name"
                    />
                    <button type="submit" className="join-button" disabled={!validatePhoneNumber(clientPhone)}>
                        Join Chat
                    </button>
                    {!validatePhoneNumber(clientPhone) && clientPhone && (
                        <p className="error-message">
                            Entry not valid
                        </p>
                    )}
                </form>
            </div>
        );
    }

    return (
        <WebSocketProvider socketRef={socketRef} operationQueue={operationQueue} sessionId={sessionId} setSessionId={setSessionId} clientPhone={clientPhone} threadId={currentThreadId} onThreadAdded={handleThreadAdded} joinThread={handleJoinedThread} clientThreads={threads}>
            <div className="app-container">
                <div
                    className={`side-drawer-container ${isMobile ? 'mobile' : ''} ${isDrawerOpen ? 'open' : ''}`}
                    style={{ width: `${drawerWidth}px` }}
                >
                    <SideDrawer
                        threads={threads}
                        currentThread={currentThreadId}
                        onThreadSelect={handleThreadSelect}
                        onNewThread={handleNewThread}
                        onJoinThread={handleJoinThread}
                        closeDrawer={toggleDrawer}
                        onLogout={handleLogout}
                    />
                </div>
                {isMobile && isDrawerOpen && (
                    <div className="backdrop" onClick={toggleDrawer}></div>
                )}
                {!isMobile && (
                    <div
                        className="resizer"
                        ref={resizerRef}
                        onMouseDown={handleMouseDown}
                    />
                )}
                <div className="chat-container">
                    {isMobile && !isDrawerOpen && (
                        <FontAwesomeIcon
                            icon={faBars}
                            className="toggle-drawer-icon"
                            onClick={toggleDrawer}
                        />
                    )}
                    <MessageList sessionId={sessionId} currentThreadId={currentThreadId} />
                    <MessageInput clientPhone={clientPhone} sessionId={sessionId} currentThreadId={currentThreadId} />
                </div>
            </div>
        </WebSocketProvider>
    );
}

export default App;
