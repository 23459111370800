import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faCirclePlus, faCopy, faUser } from '@fortawesome/free-solid-svg-icons';
import './SideDrawer.css';

const SideDrawer = ({ threads, currentThread, onThreadSelect, onNewThread, onJoinThread, closeDrawer, onLogout }) => {
    const [showNewChatModal, setShowNewChatModal] = useState(false);
    const [newThreadName, setNewThreadName] = useState('');
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [inputThreadId, setInputThreadId] = useState('');
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const dropdownRef = useRef();

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowProfileDropdown(false);
            }
        };

        if (showProfileDropdown) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showProfileDropdown]);

    const handleNewChatSubmit = () => {
        if (newThreadName.trim()) {
            onNewThread(newThreadName);
            setShowNewChatModal(false);
            setNewThreadName('');
        }
    };

    const handleJoinSubmit = () => {
        if (inputThreadId.trim()) {
            onJoinThread(inputThreadId);
            setShowJoinModal(false);
            setInputThreadId('');
        }
    };

    const handleCopyThreadId = (threadId) => {
        navigator.clipboard.writeText(threadId).then(() => {
            console.log(`Thread ID ${threadId} copied to clipboard`);
        }).catch((err) => {
            console.error('Failed to copy thread ID:', err);
        });
    };

    const handleThreadSelect = (threadId) => {
        onThreadSelect(threadId);
        closeDrawer();
    };

    return (
        <div className="side-drawer">
            <div ref={dropdownRef} className="drawer-header">
                <FontAwesomeIcon
                    icon={faUser}
                    className="drawer-icon profile-icon"
                    title="Profile"
                    onClick={() => setShowProfileDropdown((prev) => !prev)}
                />
                {showProfileDropdown && (
                    <div className="profile-dropdown">
                        <button className="dropdown-item" onClick={onLogout}>Logout</button>
                    </div>
                )}
                <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="drawer-icon"
                    onClick={() => setShowJoinModal(true)}
                    title="Join Chat"
                />
                {/* <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="drawer-icon"
                    onClick={() => setShowNewChatModal(true)}
                    title="New Chat"
                /> */}
            </div>

            <ul className="thread-list">
                {threads.map((thread) => (
                    <li
                        key={thread.id}
                        className={thread.id === currentThread ? 'thread-item selected' : 'thread-item'}
                    >
                        <span onClick={() => {
                            handleThreadSelect(thread.id);
                            // onThreadSelect(thread.id);
                            // closeDrawer();
                        }}
                            className="thread-name">
                            {thread.name}
                        </span>
                        {/* {thread.name !== 'General' && (<FontAwesomeIcon
                            icon={faCopy}
                            className="copy-icon"
                            title="Copy Thread ID"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent triggering thread selection
                                handleCopyThreadId(thread.id);
                            }}
                        />)} */}
                    </li>
                ))}
            </ul>

            {/* New Chat Modal */}
            {showNewChatModal && (
                <div className="modal-backdrop" onClick={() => setShowNewChatModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Create New Chat</h3>
                        <input
                            type="text"
                            value={newThreadName}
                            onChange={(e) => setNewThreadName(e.target.value)}
                            placeholder="Enter Chat Name"
                            onKeyDown={(e) => e.key === 'Enter' && handleNewChatSubmit()}
                        />
                        <div className="modal-actions">
                            <button onClick={handleNewChatSubmit}>Create</button>
                            <button onClick={() => setShowNewChatModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Join Chat Modal */}
            {showJoinModal && (
                <div className="modal-backdrop" onClick={() => setShowJoinModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Join Chat</h3>
                        <input
                            type="text"
                            value={inputThreadId}
                            onChange={(e) => setInputThreadId(e.target.value)}
                            placeholder="Enter Thread ID"
                            onKeyDown={(e) => e.key === 'Enter' && handleJoinSubmit()}
                        />
                        <div className="modal-actions">
                            <button onClick={handleJoinSubmit}>Join</button>
                            <button onClick={() => setShowJoinModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideDrawer;
