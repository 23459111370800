import React, { useContext, useEffect, useRef } from 'react';
import { WebSocketContext } from './WebSocketProvider';

const MessageList = ({ sessionId, currentThreadId }) => {
    const { messages } = useContext(WebSocketContext);
    const messageListRef = useRef();

    // Automatically scroll to the latest message
    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            const isAtBottom =
                messageList.scrollHeight - messageList.scrollTop === messageList.clientHeight;
            if (isAtBottom) {
                messageList.scrollTop = messageList.scrollHeight;
            }
        }
    }, [messages]);

    return (
        <div className="message-list" ref={messageListRef}>
            {messages
                .filter((msg) => msg.threadId === currentThreadId) // Only show messages in the current thread
                .map((msg, index) => (
                    <div
                        key={index}
                        className={`message-container ${msg.sessionId === sessionId ? 'sent' : 'received'}`}
                    >
                        {msg.sessionId !== sessionId && (
                            <div className="sender-name">{msg.sender}</div>
                        )}
                        {msg.text && (
                            <div className={`message ${msg.sessionId === sessionId ? 'sent' : 'received'}`}>
                                {msg.text}
                            </div>
                        )}
                        {msg.image && (
                            <div className={`message ${msg.sessionId === sessionId ? 'sent' : 'received'}`}>
                                <img
                                    src={`data:image/jpeg;base64,${msg.image}`}
                                    alt="Sent"
                                    className="message-image"
                                />
                            </div>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default MessageList;
