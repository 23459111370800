//MessageInput.js
import React, { useState, useContext, useEffect } from 'react';
import { WebSocketContext } from './WebSocketProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Compressor from 'compressorjs';

const MessageInput = ({ clientPhone, sessionId, threadId }) => {
    const [text, setText] = useState('');
    const { sendMessage } = useContext(WebSocketContext);
    // const { sendImage } = useContext(WebSocketContext);
    const [previewImage, setPreviewImage] = useState(null);
    const [compressedFile, setCompressedFile] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    // Detect mobile view
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            new Compressor(file, {
                quality: 0.6, // Compress to 60% of original quality
                success(result) {
                    stripMetadata(result);
                },
                error(err) {
                    console.error('Error compressing image:', err);
                },
            });
        }
    };

    const chunkBase64 = (base64String, chunkSize = 16000) => {
        const chunks = [];
        for (let i = 0; i < base64String.length; i += chunkSize) {
            chunks.push(base64String.slice(i, i + chunkSize));
        }
        return chunks;
    };


    const stripMetadata = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height);

                canvas.toBlob(
                    (blob) => {
                        const strippedFile = new File([blob], file.name, {
                            type: 'image/jpeg',
                        });
                        setCompressedFile(strippedFile);
                        setPreviewImage(URL.createObjectURL(blob)); // Create a preview URL for the image
                    },
                    'image/jpeg',
                    0.6
                );
            };
            img.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    const handleSend = async () => {
        if (text.trim()) {
            console.log('Message info:');
            console.log(`Sender: ${clientPhone}`);
            console.log(`Text: ${text}`);
            console.log(`Session ID: ${sessionId}`);
            console.log(`Thread ID: ${threadId}`);
            sendMessage({ sender: clientPhone, text, sessionId }, 'chat', threadId);
            setText('');
        }
        if (compressedFile) {
            const reader = new FileReader();
            reader.onload = async () => {
                const imageBase64 = reader.result.split(',')[1]; // Extract base64 part
                const chunks = chunkBase64(imageBase64);

                for (let i = 0; i < chunks.length; i++) {
                    sendMessage({ sender: clientPhone, imageChunk: chunks[i], sessionId, chunkIndex: i, totalChunks: chunks.length }, 'image_chunk', threadId);
                };

                setCompressedFile(null);
                setPreviewImage(null); // Clear the preview
            };
            reader.readAsDataURL(compressedFile);
        }
    };

    const handleRemovePreview = () => {
        setCompressedFile(null);
        setPreviewImage(null);
    };

    return (
        <div className="message-input-container">
            {previewImage && (
                <div className="preview-container">
                    <div className="image-preview">
                        <img src={previewImage} alt="Preview" className="preview-image" />
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="remove-preview-icon"
                            onClick={handleRemovePreview}
                            title="Remove Image"
                        />
                    </div>
                </div>
            )}
            <div className="input-main-line">
                <label htmlFor="image-input" className="image-upload-icon">
                    <FontAwesomeIcon icon={faPlus} />
                </label>
                <input
                    type="file"
                    id="image-input"
                    style={{ display: 'none' }}
                    accept="image/jpeg"
                    onChange={handleImageSelect}
                />
                <div className="input-preview-container">
                    <input
                        type="text"
                        className="message-input full-width"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                        placeholder="Type a message..."
                        disabled={!!previewImage} // Disable text input when an image is selected
                    />
                </div>
                {isMobile ? (
                    <button className="send-icon-button" onClick={handleSend} disabled={!text.trim() && !compressedFile}>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                ) : (
                    <button
                        className="send-button full-width"
                        onClick={handleSend}
                        disabled={!text.trim() && !compressedFile}
                    >
                        Send
                    </button>
                )}
            </div>
        </div>
    );
};

export default MessageInput;
